import { defineStore } from 'pinia'
import { useInstanceStore } from '@/store/backendInstances';
import RegionsSyncable from '@/syncing/syncables/RegionsSyncable.js';
import SyncableQueue from '@/syncing/SyncableQueue.js';

export const useRegionStore = defineStore('regions', {
  state: () => ({
    regions: {},
  }),
  persist: false,
  share: {
    enable: true,
    initialize: true,
  },
  getters: {
    getRegionTypes() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => this.regions[globalUniqueIdentifier] ? this.regions[globalUniqueIdentifier].regionTypes : null
    },
    getRegionSpecifications() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => this.regions[globalUniqueIdentifier] ? this.regions[globalUniqueIdentifier].regionSpecifications : null
    },
    getRegionMappings() {
      return (globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => this.regions[globalUniqueIdentifier] ? this.regions[globalUniqueIdentifier].regionMappings : null
    },
    getRegionMappingsForCameras() {
      return (cameraIds, globalUniqueIdentifier = useInstanceStore().selectedInstanceId) => {
        const mappings = this.getRegionMappings(globalUniqueIdentifier)
        if (!mappings) return {};
        return Object.fromEntries(Object.entries(mappings).filter(([, value]) => cameraIds.includes(value.cameraId)))
      }
    },
  },
  actions: {
    async ensureRegionsAreLoaded(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      const syncable = this.keepRegionsLoaded(globalUniqueIdentifier)
      await SyncableQueue.ensureSyncable(globalUniqueIdentifier, syncable.handle)
    },
    keepRegionsLoaded(globalUniqueIdentifier = useInstanceStore().selectedInstanceId) {
      if (SyncableQueue.doesSyncableExist(globalUniqueIdentifier, RegionsSyncable.getSyncableHandle()))  return SyncableQueue.jobs[globalUniqueIdentifier][RegionsSyncable.getSyncableHandle()]
      const syncable = new RegionsSyncable(globalUniqueIdentifier)
      SyncableQueue.addSyncable(syncable)
      return syncable
    },
  },
})
