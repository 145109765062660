import { defineStore } from 'pinia'

export const useSettingsStore = defineStore('localSettings', {
  state: () => ({
    theme: 'light',
    language: 'de',
    genericInstancesEnabled: false,
    loadBarnImages: false,
    showVersions: false,
    showTypeInformation: false,
    autoAddServiceProviders: true,
    enableAutoLogin: true,
    serviceProvidersExcludedFromAutoAdd: [],
    showCameraIdsOnGridView: false,
    languageSelected: false,
    forcedConnectionStrategy: null,
    forceConnectionStrategy: false,
    enableDeveloperSettings: false,
    instanceListDisplayMode: 'cards',
  }),
  persist: true,
  share: {
    enable: true,
    initialize: false,
  },
  getters: {},
  actions: {
    setTheme(theme) {
      this.theme = theme
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#212121');
    },
    setLanguage(language) {
      this.languageSelected = true
      this.language = language
    },
    setForcedConnectionStrategy(strategy) {
      this.forcedConnectionStrategy = strategy
    },
  },
})
