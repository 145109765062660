<script setup>
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref, watch } from 'vue';
import { DateTime } from 'luxon';

const { t } = useI18n({})

const props = defineProps({
  label: { type: String, default: '' },
  disabled: { type: Boolean, default: false },
  needsConfirmation: { type: Boolean, default: false },
  canResetToNow: { type: Boolean, default: true },
  rules: { type: Array, default: () => [] },
  max: { type: DateTime, default: null },
  min: { type: DateTime, default: null },
  hideDetails: { type: Boolean, default: false },
})

const model = defineModel({ type: DateTime, default: null })
const emit = defineEmits(['enter'])

const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
const controlKeys = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'End']
const separators = [' ', '-', '.', ':', ',', ';']
const changeKeys = [...digits, ...separators, 'Backspace', 'Delete']
const separatorIndices = [4, 7, 10, 13, 16]

const dateTimeString = ref('')
const dateTime = ref(null)
const dateTimeField = ref(null)
const menuActivated = ref(false)
const menuDate = ref(null)
const menuHours = ref('')
const menuMinutes = ref('')
const menuSeconds = ref('')
const hourField = ref(null)
const minuteField = ref(null)
const secondField = ref(null)
const dateTimeFiledErrorMessages = ref([])
const input = ref(null)


const dateTimeKeyDown = async function (event) {
  const paste = ((event.ctrlKey || event.metaKey) && event.key === 'v');
  if (paste) return undefined;
  const characterAdded = digits.includes(event.key) || separators.includes(event.key)

  //if key is illegal (no new Character, no control key), prevent propagation
  if (!characterAdded && !controlKeys.includes(event.key)) {
    return event.preventDefault()
  }

  const madeChanges = changeKeys.includes(event.key)

  //if timestamp mode is active, further evaluation is not necessary
  if (timestampMode.value) return undefined

  //if no changes are made, further evaluation is not necessary
  if (!madeChanges) return undefined

  const selectionStart = event.target.selectionStart
  const selectionEnd = event.target.selectionEnd
  const selectionCount = selectionEnd - selectionStart

  //if string would be too long, prevent propagation
  if (characterAdded && dateTimeString.value.length >= 19 && selectionCount === 0) {
    return event.preventDefault();
  }

  //if selection includes already existing separators, prevent propagation
  if (selectionCount > 0) {
    for (let separatorIndex of separatorIndices) {
      if (separatorIndex >= selectionStart && separatorIndex < selectionEnd) {
        return event.preventDefault();
      }
    }
  }


  //insert separator if necessary
  if (characterAdded && separatorIndices.includes(selectionStart)) {
    let separator
    if (selectionStart === separatorIndices[0] || selectionStart === separatorIndices[1]) separator = '-'
    if (selectionStart === separatorIndices[2]) separator = ' '
    if (selectionStart === separatorIndices[3] || selectionStart === separatorIndices[4]) separator = ':'

    const elements = dateTimeString.value.split('')
    elements.splice(selectionStart, 0, separator)
    dateTimeString.value = elements.join('')
  }
  //check if premature separator was inserted
  if (separators.includes(event.key)) {
    if (selectionStart === 2) {
      dateTimeString.value = `20${dateTimeString.value}`
    } else {
      if (!separatorIndices.includes(selectionStart - 1) && !separatorIndices.includes(selectionStart)) {
        const elements = dateTimeString.value.split('')
        elements.splice(selectionStart - 1, 0, '0')
        dateTimeString.value = elements.join('')
      }
    }
    return event.preventDefault();
  }


}

const validateDateTimeString = function (value) {
  if (!value.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/)) {
    return t('dateTimePicker.errors.format_mismatch')
  }
  const date = DateTime.fromMillis(Date.parse(value))
  if (date instanceof DateTime && !isNaN(date.toMillis())) return true;
  return t('dateTimePicker.errors.string_invalid')
}


const timeSegmentFieldKeyDown = function (event, min, max, oldValue, newFocusElement) {
  if (controlKeys.includes(event.key)) return;
  if (!digits.includes(event.key)) return event.preventDefault()
  const selectionStart = event.target.selectionStart
  const selectionEnd = event.target.selectionEnd
  const selectionCount = selectionEnd - selectionStart
  const elements = oldValue.value.length > 0 ? oldValue.value.split('') : []
  elements.splice(selectionStart, selectionCount, event.key)
  const newValue = parseInt(elements.join(''));
  if (newValue < min || newValue > max) return event.preventDefault()
  if (newValue.toString().length === 2 && newFocusElement) {
    oldValue.value = newValue.toString()
    newFocusElement.value.focus()
    return event.preventDefault()
  }
}
const hourFieldKeyDown = event => timeSegmentFieldKeyDown(event, 0, 24, menuHours, minuteField)
const minuteFieldKeyDown = event => timeSegmentFieldKeyDown(event, 0, 59, menuMinutes, secondField)
const secondFieldKeyDown = event => timeSegmentFieldKeyDown(event, 0, 59, menuSeconds)


const setMenuValues = function () {
  menuHours.value = dateTime.value.hour.toString().padStart(2, '0')
  menuMinutes.value = dateTime.value.minute.toString().padStart(2, '0')
  menuSeconds.value = dateTime.value.second.toString().padStart(2, '0')
  menuDate.value = dateTime.value
}

const confirmMenu = function () {
  dateTime.value = DateTime.fromMillis(menuDate.value.toMillis()).set({
    hours: parseInt(menuHours.value),
    minutes: parseInt(menuMinutes.value),
    seconds: parseInt(menuSeconds.value),
    milliseconds: 0,
  })
  menuActivated.value = false;
  propagateDateTimeChange()
  if (props.needsConfirmation)
    model.value = dateTime.value
}

const dateTimeBlur = function () {
  if (menuActivated.value) return
  if (timestampMode.value === false) {
    const validation = validateDateTimeString(dateTimeString.value)
    if (validation === true) {
      dateTimeFiledErrorMessages.value = [];
      dateTime.value = DateTime.fromMillis(Date.parse(dateTimeString.value))
      propagateDateTimeChange()
      return;
    }
    dateTimeFiledErrorMessages.value = [validation]
  }
}

const propagateDateTimeChange = function () {
  if (menuActivated.value) return;
  if (!dateTime.value) return;
  const year = dateTime.value.year.toString().padStart(4, '0')
  const month = (dateTime.value.month).toString().padStart(2, '0')
  const date = dateTime.value.day.toString().padStart(2, '0')
  const hours = dateTime.value.hour.toString().padStart(2, '0')
  const minutes = dateTime.value.minute.toString().padStart(2, '0')
  const seconds = dateTime.value.second.toString().padStart(2, '0')
  dateTimeString.value = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`

  menuDate.value = dateTime.value
  menuHours.value = hours
  menuMinutes.value = minutes
  menuSeconds.value = seconds

  if (!model.value || model.value.toMillis() !== dateTime.value.toMillis()) {
    if (!props.needsConfirmation)
      model.value = dateTime.value
  }
}

const timestampMode = computed(() => {
  return false
})

const hint = computed(() => {
  return 'YYYY-mm-dd HH:MM:SS'
})

async function setToNow() {
  dateTime.value = DateTime.now().startOf('second')
  menuActivated.value = false;
  propagateDateTimeChange()
  if (props.needsConfirmation)
    model.value = dateTime.value
}

onMounted(() => {
  dateTime.value = DateTime.now()
  if (model.value) dateTime.value = model.value;
  dateTime.value = dateTime.value.startOf('second')
  propagateDateTimeChange();
})

watch(() => model.value, (after, before) => {
  if (before && after && before.toMillis() === after.toMillis()) return;
  if (after && dateTime.value && after.toMillis() === dateTime.value.toMillis()) return;
  dateTime.value = after;
  propagateDateTimeChange()
  if (props.needsConfirmation)
    model.value = dateTime.value
}, { deep: true })

defineExpose({
  validate: input.value?.validate,
  reset: input.value?.reset,
  errorMessages: input.value?.errorMessages,
  resetValidation: input.value?.resetValidation,
  isValid: input.value?.isValid,
  dateTimeBlur,
})


</script>

<template>
  <v-input
    ref="input"
    :validation-value="model"
    :rules="rules"
    :hide-details="props.hideDetails"
  >
    <template #default>
      <v-container class="ma-0 pa-0">
        <v-label
          v-if="props.label"
          :text="props.label"
          class="ms-2"
        />

        <v-text-field
          ref="dateTimeField"
          v-model="dateTimeString"
          rounded
          :disabled="disabled"
          :label="label"
          style="width:250px"
          variant="outlined"
          :single-line="true"
          class="ma-2"
          append-inner-icon="mdi-calendar-month"
          :hint="hint"
          :persistent-hint="false"
          :error-messages="dateTimeFiledErrorMessages"
          :hide-details="props.hideDetails"
          @keyup.enter="() => {dateTimeBlur(); emit('enter')}"
          @keydown="dateTimeKeyDown"
          @blur="dateTimeBlur"
          @click:control="e => e.stopPropagation()"
          @click:append-inner="(event) => { menuActivated = !menuActivated; setMenuValues(); event.stopPropagation();}"
        />
      </v-container>
      <v-menu
        v-model="menuActivated"
        :activator="dateTimeField"
        :close-on-content-click="false"
        open
      >
        <v-card style="border-radius: 32px">
          <v-row
            class="justify-center"
            :no-gutters="true"
          >
            <v-col :cols="12">
              <v-date-picker
                :model-value="new Date(menuDate.toMillis())"
                :hide-actions="true"
                title=" "
                :min="props.min ? new Date(props.min.toMillis()) : null"
                :max="props.max ? new Date(props.max.toMillis()) : null"
                class="w-100"
                @update:model-value="date => menuDate = DateTime.fromMillis(date.getTime())"
              />
            </v-col>
            <v-col :cols="12">
              <v-layout class="justify-center align-center ma-4">
                <v-text-field
                  ref="hourField"
                  v-model="menuHours"
                  :placeholder="$t('general_interface.durations.hours')"
                  style="max-width: 100px"
                  :center-affix="true"
                  variant="outlined"
                  :hide-details="true"
                  :min="0"
                  :max="24"
                  @keydown="hourFieldKeyDown"
                />
                <v-chip
                  variant="text"
                  size="x-large"
                  class="justify-center font-weight-bold"
                  text=":"
                />
                <v-text-field
                  ref="minuteField"
                  v-model="menuMinutes"
                  :placeholder="$t('general_interface.durations.minutes')"
                  style="max-width: 100px"
                  :center-affix="true"
                  variant="outlined"
                  :hide-details="true"
                  :min="0"
                  :max="59"
                  @keydown="minuteFieldKeyDown"
                />
                <v-chip
                  variant="text"
                  size="x-large"
                  class="justify-center font-weight-bold"
                  text=":"
                />
                <v-text-field
                  ref="secondField"
                  v-model="menuSeconds"
                  :placeholder="$t('general_interface.durations.seconds')"
                  style="max-width: 100px"
                  :center-affix="true"
                  variant="outlined"
                  :hide-details="true"
                  :min="0"
                  :max="59"
                  @keydown="secondFieldKeyDown"
                />
              </v-layout>
            </v-col>
          </v-row>
          <v-btn
            v-if="canResetToNow"
            :block="true"
            variant="text"
            class="rounded-pill"
            @click="setToNow"
          >
            {{ $t("general_interface.buttons.now") }}
          </v-btn>
          <v-card-actions class="justify-end">
            <v-btn
              variant="text"
              color="error"
              class="rounded-pill"
              @click="menuActivated = false"
            >
              {{ $t("general_interface.buttons.cancel") }}
            </v-btn>
            <v-btn
              class="rounded-pill"
              variant="flat"
              color="primary"
              @click="confirmMenu"
            >
              {{ $t("general_interface.buttons.confirm") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>
  </v-input>
</template>

<style scoped>

</style>
