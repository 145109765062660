<script setup>
import DeadAnimalChart from '@/components/incidents/incidentCharts/DeadAnimalChart.vue';
import { useIncidentStore } from '@/store/incident';
import { computed, onMounted, ref, watch } from 'vue';
import DailySegmentIncidentTable from '@/components/incidents/DailySegmentIncidentTable.vue';
import DateTimePicker from '@/components/DateTimePicker.vue';
import { humanReadableDuration } from '@/composables/datetime';
import { useRoute, useRouter } from 'vue-router';
import i18n from '@/plugins/i18n';
import { splittingModes } from '@/composables/incidents/incidentPlottingHelpers';
import { DateTime } from 'luxon';
import DailyTimestampIncidentTable from '@/components/incidents/DailyTimestampIncidentTable.vue';
import OutdatedAlert from '@/components/OutdatedAlert.vue';

const incidentStore = useIncidentStore()
const route = useRoute()
const router = useRouter()
const { t } = i18n.global

const maxTimeShift = 23
const minTimeShift = 0

const props = defineProps({
  useQuery: { type: Boolean, default: false },
  preDefinedDays: { type: Number, default: 3 },
  preDefinedTimeResolution: { type: Number, default: 6 },
  showSettings: { type: Boolean, default: true },
})

const segmentLengths = [1, 2, 4, 6, 8, 12, 24]
const availableTimeShifts = Array.from({ length: maxTimeShift + 1 }, (_, i) => i).map(i => ({ text: humanReadableDuration(i * 60 * 60 * 1000, undefined, ['h']), value: i }))
const availableSegmentLengths = segmentLengths.map(length => ({
  value: length,
  title: humanReadableDuration(length * 60 * 60 * 1000),
}))

const incidents = ref([])
const showTimeShiftDialog = ref(false)
const showResolutionDialog = ref(false)
const start = ref(DateTime.now())
const end = ref(DateTime.now())
const timeResolution = ref(props.preDefinedTimeResolution)
const timeShift = ref(0)
const selectedTimeShift = ref(0)
const selectedTimeResolution = ref(props.preDefinedTimeResolution)
const selectedMode = ref(splittingModes.open)
const selectedStart = ref(DateTime.now())
const selectedEnd = ref(DateTime.now())

const loading = ref(false)
const mode = ref(splittingModes.open)
const modeDialog = ref(false)

const isOutdated = computed(() => {
  if(selectedTimeResolution.value !== timeResolution.value) return true
  if(selectedTimeShift.value !== timeShift.value) return true
  if(selectedStart.value !== start.value) return true
  if(selectedEnd.value !== end.value) return true
  if(selectedMode.value !== mode.value) return true
  return false
})

const displayModeExplanation = computed(() => {
  return t(`dashboards.dead_animal_statistics.modes.${selectedMode.value}.explanation`)
})

const splittingModeColor = computed(() => {
  if (selectedMode.value === splittingModes.opened) return 'orange'
  if (selectedMode.value === splittingModes.closed) return 'green'
  return 'blue'
})

const segmentOrTimeResolutionLabel = computed(() => {
  if(selectedMode.value === splittingModes.open) return t('dashboards.dead_animal_statistics.time_resolution')
  return t('dashboards.dead_animal_statistics.segment_length')
})

const segmentOrTimeResolutionExplanation = computed(() => {
  if(selectedMode.value === splittingModes.open) return t('dashboards.dead_animal_statistics.time_resolution_explanation')
  return t('dashboards.dead_animal_statistics.segment_explanation')
})

watch(() => [start.value, end.value, timeResolution.value, mode.value, timeShift.value], () => {
  if (props.useQuery) {
    const query = {
      start: start.value.toMillis(),
      end: end.value.toMillis(),
      timeResolution: timeResolution.value,
      mode: mode.value,
      timeShift: timeShift.value,
    }
    router.replace({ query })
  }
})


async function load() {
  timeResolution.value = selectedTimeResolution.value
  timeShift.value = selectedTimeShift.value
  mode.value = selectedMode.value
  start.value = selectedStart.value
  end.value = selectedEnd.value

  loading.value = true
  incidents.value = await incidentStore.loadIncidentsOpenBetweenSpecificTimestamps(
    ['deadAnimal'],
    start.value.minus({ hours: minTimeShift }).toMillis(),
    end.value.plus({ hours: maxTimeShift }).toMillis(),
    { only: ['state', 'incidentId', 'firstEventTimestamp', 'lastEventTimestamp', 'type'] },
    { firstEventTimestamp: 'asc' },
    [{ key: 'messagePayload.falsePositive', operator: 'exists', value: false }],
  )
  loading.value = false
}

onMounted(async () => {
  start.value = DateTime.now().minus({ days: props.preDefinedDays }).startOf('day');
  end.value = DateTime.now()

  if (props.useQuery) {
    const queryParams = route.query
    if (queryParams.start) start.value = DateTime.fromMillis(parseInt(queryParams.start))
    if (queryParams.end) end.value = DateTime.fromMillis(parseInt(queryParams.end))
    if (queryParams.timeResolution) timeResolution.value = parseInt(queryParams.timeResolution)
    if (queryParams.mode) mode.value = queryParams.mode
    if (queryParams.timeShift) timeShift.value = parseInt(queryParams.timeShift)
  }

  selectedStart.value = start.value
  selectedEnd.value = end.value
  selectedMode.value = mode.value
  selectedTimeShift.value = timeShift.value
  selectedTimeResolution.value = timeResolution.value
  await load()
})


</script>

<template>
  <v-layout-card
    v-if="showSettings"
  >
    <v-card-title>
      {{ $t('dashboards.dead_animal_statistics.loading_settings') }}
    </v-card-title>
    <v-card-text>
      <v-row class="align-center justify-center">
        <v-col cols="auto">
          <DateTimePicker
            v-model="selectedStart"
            :label="$t('general_interface.date_time_picker.start')"
            @enter="load"
          />
        </v-col>
        <v-col cols="auto">
          <DateTimePicker
            v-model="selectedEnd"
            :label="$t('general_interface.date_time_picker.end')"
            @enter="load"
          />
        </v-col>
        <v-col cols="auto">
          <v-layout-card
            :color="splittingModeColor"
            @click="modeDialog = true"
          >
            <v-card-subtitle>{{ $t('dashboards.dead_animal_statistics.display_mode') }}</v-card-subtitle>
            <v-card-text>
              <p v-html="displayModeExplanation" />
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn class="rounded-pill">
                {{ $t('dashboards.dead_animal_statistics.change_mode') }}
                <v-dialog
                  v-model="modeDialog"
                  max-width="1000px"
                  width="auto"
                  activator="parent"
                >
                  <v-sheet style="border-radius: 32px">
                    <v-layout-card
                      variant="flat"
                      elevation="0"
                      color="blue"
                      @click="selectedMode = splittingModes.open; modeDialog = false"
                    >
                      <v-card-title>
                        {{
                          $t(`dashboards.dead_animal_statistics.modes.${splittingModes.open}.title`)
                        }}
                      </v-card-title>
                      <v-card-text>
                        {{
                          $t(`dashboards.dead_animal_statistics.modes.${splittingModes.open}.text`)
                        }}
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          class="rounded-pill"
                          append-icon="mdi-arrow-right"
                          @click="selectedMode = splittingModes.opened; modeDialog = false"
                        >
                          {{ $t(`dashboards.dead_animal_statistics.modes.${splittingModes.open}.button`) }}
                        </v-btn>
                      </v-card-actions>
                    </v-layout-card>
                    <v-layout-card
                      variant="flat"
                      elevation="0"
                      color="orange"
                      @click="selectedMode = splittingModes.opened; modeDialog = false"
                    >
                      <v-card-title>
                        {{
                          $t(`dashboards.dead_animal_statistics.modes.${splittingModes.opened}.title`)
                        }}
                      </v-card-title>
                      <v-card-text>
                        {{
                          $t(`dashboards.dead_animal_statistics.modes.${splittingModes.opened}.text`)
                        }}
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          class="rounded-pill"
                          append-icon="mdi-arrow-right"
                          @click="selectedMode = splittingModes.opened; modeDialog = false"
                        >
                          {{ $t(`dashboards.dead_animal_statistics.modes.${splittingModes.opened}.button`) }}
                        </v-btn>
                      </v-card-actions>
                    </v-layout-card>
                    <v-layout-card
                      variant="flat"
                      elevation="0"
                      color="green"
                      @click="selectedMode = splittingModes.closed; modeDialog = false"
                    >
                      <v-card-title>
                        {{
                          $t(`dashboards.dead_animal_statistics.modes.${splittingModes.closed}.title`)
                        }}
                      </v-card-title>
                      <v-card-text>
                        {{ $t(`dashboards.dead_animal_statistics.modes.${splittingModes.closed}.text`) }}
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn
                          class="rounded-pill"
                          append-icon="mdi-arrow-right"
                          @click="selectedMode = splittingModes.closed; modeDialog = false"
                        >
                          {{ $t(`dashboards.dead_animal_statistics.modes.${splittingModes.closed}.button`) }}
                        </v-btn>
                      </v-card-actions>
                    </v-layout-card>
                  </v-sheet>
                </v-dialog>
              </v-btn>
            </v-card-actions>
          </v-layout-card>
        </v-col>

        <v-col :cols="12">
          <v-row class="justify-center align-center">
            <v-col
              :cols="12"
              sm="4"
              lg="2"
            >
              <v-autocomplete
                v-model="selectedTimeResolution"
                variant="outlined"
                style="min-width: 200px;"
                class="ma-2"
                :items="availableSegmentLengths"
                :label="segmentOrTimeResolutionLabel"
                item-text="title"

                item-value="value"
              >
                <template #append>
                  <v-btn
                    size="small"
                    variant="outlined"
                    :icon="true"
                  >
                    <v-icon icon="mdi-help" />
                    <v-dialog
                      v-model="showResolutionDialog"
                      max-width="500px"
                      activator="parent"
                    >
                      <v-card>
                        <v-card-title>{{ segmentOrTimeResolutionLabel }}</v-card-title>

                        <v-card-text>{{ segmentOrTimeResolutionExplanation }}</v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            class="rounded-pill"
                            variant="flat"
                            color="success"
                            @click="showResolutionDialog=false"
                          >
                            {{ $t("general_interface.buttons.back") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-btn>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              :cols="12"
              sm="4"
              lg="2"
            >
              <v-select
                v-model="selectedTimeShift"
                :return-object="false"
                variant="outlined"
                style="min-width:200px;"
                :items="availableTimeShifts"
                item-value="value"
                item-title="text"
                :label="$t('dashboards.dead_animal_statistics.time_shift')"
              >
                <template #append>
                  <v-btn
                    class="rounded-pill"
                    size="small"
                    variant="outlined"
                    :icon="true"
                  >
                    <v-icon icon="mdi-help" />
                    <v-dialog
                      v-model="showTimeShiftDialog"
                      max-width="500px"
                      activator="parent"
                    >
                      <v-card>
                        <v-card-title>{{ $t('dashboards.dead_animal_statistics.time_shift') }}</v-card-title>

                        <v-card-text>{{ $t('dashboards.dead_animal_statistics.time_shift_explanation') }}</v-card-text>
                        <v-card-actions class="justify-end">
                          <v-btn
                            variant="flat"
                            color="success"
                            class="rounded-pill"
                            @click="showTimeShiftDialog=false"
                          >
                            {{ $t("general_interface.buttons.back") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-btn>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        variant="flat"
        color="primary"
        class="rounded-pill"
        @click="load"
      >
        {{ $t("general_interface.buttons.reload") }}
      </v-btn>
    </v-card-actions>
  </v-layout-card>
  <v-layout-card
    :loading="loading"
  >
    <v-card-title>{{ $t("dashboards.dead_animal_statistics.title") }}</v-card-title>
    <v-card-text>
      <DeadAnimalChart
        v-if="incidents.length > 0 && !loading"
        class="ma-2"
        :mode="mode"
        :start="start.toMillis()"
        :end="end.toMillis()"
        :time-resolution="timeResolution"
        :time-shift="timeShift"
        :incidents="incidents"
        :dimension-key="null"
      />
      <DailyTimestampIncidentTable
        v-if="mode === splittingModes.open && incidents.length > 0 && !loading"
        class="ma-2"
        :start="start.toMillis()"
        :end="end.toMillis()"
        :time-resolution="timeResolution"
        :time-shift="timeShift"
        :incidents="incidents"
        :dimension-key="null"
      />
      <DailySegmentIncidentTable
        v-if="mode !== splittingModes.open && incidents.length > 0 && !loading"
        class="ma-2"
        :mode="mode"
        :start="start.toMillis()"
        :end="end.toMillis()"
        :time-resolution="timeResolution"
        :time-shift="timeShift"
        :incidents="incidents"
        :dimension-key="null"
      />
    </v-card-text>
  </v-layout-card>
  <OutdatedAlert
    v-if="isOutdated"
    @click="load"
  />
</template>

<style scoped>

</style>
