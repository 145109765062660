import Syncable from "@/syncing/Syncable.js";
import { useIncidentStore } from "@/store/incident";
import { useInstanceStore } from "@/store/backendInstances.js";
import _ from "lodash";
import { canViewIncidentType } from "@/composables/permissionHelper";

class PenStateDurationSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(
      globalUniqueIdentifier,
      1000 * 60 * 60, // 1 hour
      PenStateDurationSyncable.getSyncableHandle(),
      true,
      true
    );
  }

  /**
   * Checks if the preconditions for syncing the pen state duration are satisfied.
   *
   * Preconditions:
   * - The instance type of the global unique identifier must be "barn".
   * - The super class must satisfy the preconditions (is online and logged in).
   *
   * @returns {boolean} - Returns true if the preconditions are satisfied, otherwise false.
   */
  satisfiesPreconditions() {
    if (
      useInstanceStore().instances[this.globalUniqueIdentifier].instanceType !==
      "barn"
    )
      return false;

    if (!canViewIncidentType("penState", this.globalUniqueIdentifier))
      return false;

    return super.satisfiesPreconditions();
  }

  /**
   * Performs the workload for syncing the pen state duration.
   *
   * The workload involves:
   * - Loading the latest pen state for the given global unique identifier.
   * - If no pen state is found, setting the pen state duration to null.
   *
   * - Loading the pen state duration for the latest pen state.
   * - If no pen state duration is found, exiting the function.
   *
   * - Comparing the loaded pen state duration with the current pen state duration.
   * - If they are not equal, updating the pen state duration in the incident store.
   *
   * @async
   * @returns {Promise<void>} - A promise that resolves when the workload is complete.
   */
  async workload() {
    const latestPenState = await useIncidentStore().loadLatestPenState(
      this.globalUniqueIdentifier
    );
    if (!latestPenState) {
      /*
       * NOTE:
       * If there is no pen state, we set the pen state duration to null.
       * This will help when handling the loading state (undefined => loading=true, null => loading=false).
       */
      useIncidentStore().penStateDuration[this.globalUniqueIdentifier] = null;
      return;
    }

    const loadedPenStateDuration =
      await useIncidentStore().loadPenStateDuration(latestPenState);
    if (!loadedPenStateDuration) return;

    const currentPenStateDuration =
      await useIncidentStore().getPenStateDuration(this.globalUniqueIdentifier);
    if (
      currentPenStateDuration &&
      _.isEqual(currentPenStateDuration, loadedPenStateDuration)
    )
      return;

    useIncidentStore().penStateDuration[this.globalUniqueIdentifier] =
      loadedPenStateDuration;
  }

  static getSyncableHandle() {
    return "pen-state-duration";
  }
}

export default PenStateDurationSyncable;
